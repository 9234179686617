/**
 * 基础路由
 * @type { *[] }
 */
import { AppSider, Menu } from "@/layouts";

const RouteView = {
  name: "RouteView",
  render: (h) => h("router-view"),
};

export const constantRouterMap = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index"),
  },
  {
    path: "/",
    component: AppSider,
    children: [
      {
        path: "/client",
        name: "client",
        component: RouteView,
        redirect: { name: "home" },
        children: [
          {
            path: "/client/home/index",
            name: "home",
            meta: { isLogin: true },
            component: () => import("@/views/home/index"),
          },
          {
            path: "/client/shortVideo/index",
            name: "shortVideo",
            meta: { isLogin: true },
            component: () => import("@/views/shortVideo/index"),
          },
          {
            path: "/client/painting/index",
            name: "painting",
            meta: { isLogin: true },
            component: () => import("@/views/painting/index"),
          },
          {
            path: "/client/setting/index",
            name: "setting",
            meta: { isLogin: true },
            component: () => import("@/views/setting/index"),
          },
          {
            path: "/client/setting/edit",
            name: "edit",
            meta: { isLogin: true },
            component: () => import("@/views/setting/edit"),
          },
          {
            path: "/client/live/index",
            name: "live",
            meta: { isLogin: true },
            component: () => import("@/views/live/index"),
          },
          {
            path: "/client/realTime/index",
            name: "realTime",
            meta: { isLogin: true },
            component: () => import("@/views/realTime/index"),
          },
          {
            path: "/client/realTime/create",
            name: "realTimeCreate",
            meta: { isLogin: true },
            component: () => import("@/views/realTime/create"),
          },
          {
            path: "/client/gpt/index",
            name: "text",
            meta: { isLogin: true },
            component: () => import("@/views/gpt/index"),
          },
          {
            path: "/client/knowledge/index",
            name: "knowledge",
            meta: { isLogin: true },
            component: () => import("@/views/knowledge/index"),
          },
          {
            path: "/client/knowledge/detail",
            name: "knowledgeDetail",
            meta: { isLogin: true },
            component: () => import("@/views/knowledge/detail"),
          },
          {
            path: "/client/knowledge/chat/index",
            name: "knowledgeChat",
            meta: { isLogin: true },
            component: () => import("@/views/knowledge/chat/index"),
          },
          {
            path: "/client/home/clone",
            name: "clone",
            meta: { isLogin: true },
            component: () => import("@/views/home/clone"),
          },
          {
            path: "/client/home/audioClone",
            name: "audioClone",
            meta: { isLogin: true },
            component: () => import("@/views/home/audioClone"),
          },
        ],
      },
      // {
      //   path: '/example',
      //   name: 'Example',
      //   component: Menu,
      //   props: { id: 'example' },
      //   redirect: { name: 'ExampleHelloIndex' },
      //   children: [
      //     {
      //       path: '/example/hello/index',
      //       name: 'ExampleHelloIndex',
      //       component: () => import('@/views/example/hello/Index')
      //     },
      //   ]
      // },
    ],
  },
  {
    path: "/special",
    component: RouteView,
    children: [
      {
        path: "subwindow",
        name: "SpecialSubwindowIpc",
        component: () => import("@/views/liveStreaming/index"),
      },
    ],
  },
];
